<template>
  <ul class="topic">
    <li v-for="(item, index) in hots" :key="index" @click="detail(item.id, item.teacher_code)">
      <van-image lazy-load round :src="item.image || item.avatar" fit="cover" />
      <p class="cate_name">{{ item.title }}</p>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    hots: {
      type: Array
    },
    clickType: {
      type: String,
      default: 'course'
    }
  },
  methods: {
    detail (id, code) {
      let path = ''
      if (this.clickType === 'course') {
        path = { name: 'list', params: { id } }
      } else {
        path = {
          path: `/teacher/${id}/${code}`
        }
      }
      this.$router.push(path)
    }
  }
}
</script>

<style lang="less" scoped>
// 专题
.topic {
  display: flex;
  flex-wrap: wrap;
  padding-top: 20px;
  background-color: #fff;
  padding-bottom: 40px;
  li {
    position: relative;
    display: block;
    width: 33.3333%;
    margin: 0;
    text-align: center;
    /deep/ .van-image {
      width: 230px;
      height: 180px;
      border-radius: 8px !important;
      object-fit: cover;
      margin-bottom: 14px;
      vertical-align: top;
    }
    .cate_name {
      font-size: 26px;
      text-align: center;
      padding: 0 20px;
      color: #3a3a3a;
      margin-bottom: 10px;
    }
  }
}
</style>
