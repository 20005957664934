<template>
  <div class="container rtl">
    <van-nav-bar
      title="الاتاۋ كەسكىن ارناسى"
      @click-left="$router.back()"
      @click-right="$router.push('/')"
      fixed
    >
      <div slot="left" style="color: #fff; font-size: 16px">
        قايتۋ
        <van-icon name="arrow-left" size="16px" />
      </div>
      <div slot="right" style="color: #fff; font-size: 16px">
        <van-icon name="wap-home-o" size="18px" />
        باس بەت
      </div>
    </van-nav-bar>

    <van-list
      v-model="loading"
      :finished="finished"
      :finished-text="isEmpty ? 'قازىرشە مازمۇن جوق' : ''"
      :error.sync="error"
      error-text="مازمۇن ىزدەلۋ ۇستىندە، بۇل جەردى باسىڭىز"
      @load="onLoad"
      loading-text="ىزدەلۋدە"
    >
      <TeacherItem v-if="topicList" :hots="topicList" clickType="topic" />
    </van-list>
    <van-empty v-show="isEmpty" />
  </div>
</template>

<script>
import TeacherItem from '@/components/TeacherItem'
import { getAllTeacher } from '@/api/Teaher'
export default {
  name: 'List',
  props: {},
  data () {
    return {
      pages: {
        pagenum: 1,
        pagesize: 9,
        total_pages: null,
        total: null
      },
      loading: false, // 上拉加载更多的 loading 状态
      finished: false, // 是否加载结束
      error: false, // 是否加载失败
      topicList: [] // 专题列表
    }
  },
  computed: {
    isEmpty () {
      return this.finished && !this.pages.total && !this.courses.length
    }
  },
  mounted () {
    document.title = 'اۆتورلار'
  },
  components: {
    TeacherItem
  },
  methods: {
    // 监听底部事件
    async onLoad () {
      try {
        // 1. 请求获取数据
        const { data } = await getAllTeacher({
          ...this.pages
        })

        // 2. 把数据添加到 topicList 数组中
        const results = data.data
        this.topicList.push(...results)
        this.pages.total = data.meta.total

        // 3. 设置本次加载中 loading 状态结束
        this.loading = false
        const { pagenum, pagesize } = this.pages
        // 4. 判断数据是否加载结束
        if (pagenum * pagesize >= data.meta.total) {
          // 没有数据了，设置加载状态结束，不再触发上拉加载更多了
          this.finished = true
        } else {
          // 更新获取下一页数据的时间戳
          this.pages.pagenum = pagenum + 1
        }
      } catch (err) {
        console.log(err)
        this.loading = false // 关闭 loading 效果
        this.error = true // 开启错误提示
      }
    }
  }
}
</script>
<style lang="less" scoped>
.container {
  padding-top: 92px;
  padding-bottom: 50px;
}
</style>
